import Types from "@/constant/column-types";

export const types = {};

// define field types
types[-7] = "BIT";
types[-6] = "TINYINT";
types[5] = "SMALLINT";
types[4] = "INTEGER";
types[-5] = "BIGINT";
types[6] = "FLOAT";
types[7] = "REAL";
types[8] = "DOUBLE";
types[2] = "NUMERIC";
types[3] = "DECIMAL";
types[1] = "CHAR";
types[12] = "VARCHAR";
types[-1] = "LONGVARCHAR";
types[91] = "DATE";
types[92] = "TIME";
types[93] = "TIMESTAMP";
types[-2] = "BINARY";
types[-3] = "VARBINARY";
types[-4] = "LONGVARBINARY";
types[0] = "NULL";
types[1111] = "OTHER";
types[2000] = "JAVA_OBJECT";
types[2001] = "DISTINCT";
types[2002] = "STRUCT";
types[2003] = "ARRAY";
types[2004] = "BLOB";
types[2005] = "CLOB";
types[2006] = "REF";
types[70] = "DATALINK";
types[16] = "BOOLEAN";
types[-8] = "ROWID";
types[-15] = "NCHAR";
types[-9] = "NVARCHAR";
types[-16] = "LONGNVARCHAR";
types[2011] = "NCLOB";
types[2009] = "SQLXML";
types[2012] = "REF_CURSOR";
types[2013] = "TIME_WITH_TIMEZONE";
types[2014] = "TIMESTAMP_WITH_TIMEZONE";

export const CastType = {};

CastType["BIT"] = Types.number;
CastType["TINYINT"] = Types.number;
CastType["SMALLINT"] = Types.number;
CastType["INTEGER"] = Types.number;
CastType["BIGINT"] = Types.number;
CastType["FLOAT"] = Types.number;
CastType["REAL"] = Types.number;
CastType["DOUBLE"] = Types.number;
CastType["NUMERIC"] = Types.number;
CastType["DECIMAL"] = Types.number;
CastType["CHAR"] = Types.text;
CastType["VARCHAR"] = Types.text;
CastType["LONGVARCHAR"] = Types.text;
CastType["DATE"] = Types.date;
CastType["TIME"] = Types.date;
CastType["TIMESTAMP"] = Types.date;
CastType["BINARY"] = Types.number;
CastType["VARBINARY"] = Types.number;
CastType["LONGVARBINARY"] = Types.number;
CastType["NULL"] = Types.text;
CastType["OTHER"] = Types.text;
CastType["JAVA_OBJECT"] = Types.text;
CastType["DISTINCT"] = Types.text;
CastType["STRUCT"] = Types.text;
CastType["ARRAY"] = Types.text;
CastType["BLOB"] = Types.text;
CastType["CLOB"] = Types.text;
CastType["REF"] = Types.text;
CastType["DATALINK"] = Types.text;
CastType["BOOLEAN"] = Types.text;
CastType["ROWID"] = Types.text;
CastType["NCHAR"] = Types.text;
CastType["NVARCHAR"] = Types.text;
CastType["LONGNVARCHAR"] = Types.text;
CastType["NCLOB"] = Types.text;
CastType["SQLXML"] = Types.text;
CastType["REF_CURSOR"] = Types.text;
CastType["TIME_WITH_TIMEZONE"] = Types.date;
CastType["TIMESTAMP_WITH_TIMEZONE"] = Types.date;

export default types;
