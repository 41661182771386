/**
 * 获取当前光标位置的 token
 * @param {import("monaco-editor/esm/vs/editor/editor.api").editor.ITextModel} model
 * @param {import("monaco-editor/esm/vs/editor/editor.api").Position} position
 * @returns {string}
 */
export function tokenOfPointer(model, position) {
  const lineText = model.getValueInRange({
    startLineNumber: position.lineNumber,
    startColumn: 0,
    endLineNumber: position.lineNumber,
    endColumn: position.column
  });
  return lineText
    .split(/\s+/)
    .pop()
    .toLowerCase();
}

/**
 * 获取整个文档的单词
 * @param {import("monaco-editor/esm/vs/editor/editor.api").editor.ITextModel} model
 * @returns {string[]}
 */
export function tokens(model) {
  const words = model
    .getValue()
    .slice(0, 3000)
    .match(/\w+/g);
  return Array.from(new Set(words));
}
