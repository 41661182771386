<template>
  <div class="sql-editor">
    <!-- monaco-editor 没有 placeholder 功能，因此自定义 -->
    <span v-show="!value" class="placeholder">-- 请输入 SQL 语句</span>
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
import debounce from "lodash/debounce";
import { LANG_ID } from "./language";

export default {
  props: {
    value: String
  },
  mounted() {
    const editor = monaco.editor.create(this.$el, {
      value: this.value,
      language: LANG_ID,
      lineNumbers: "on",
      lineHeight: 24,
      renderLineHighlight: false,
      fontSize: 13,
      fixedOverflowWidgets: true,
      padding: {
        // 记得同时修改 placeholder 的 top 位置
        top: 12,
        bottom: 0
      },
      scrollbar: {
        verticalScrollbarSize: 8,
        horizontalScrollbarSize: 8
      }
    });
    // 监听内容变化
    editor.onDidChangeModelContent(() => {
      this.$emit("input", editor.getValue());
    });
    // auto focus
    editor.focus();
    // resize 时重新 layout
    const callback = debounce(function() {
      editor.layout();
    }, 300);
    const observer = new ResizeObserver(callback);
    observer.observe(this.$el);
    this.editor = editor;
  },
  destroyed() {
    this.editor.dispose();
  }
};
</script>

<style lang="less" scoped>
@import "~@/views/datamodel/assets/vars.less";

.sql-editor {
  height: 100%;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  .placeholder {
    position: absolute;
    left: 65px;
    font-size: 13px;
    line-height: 24px;
    color: fade(black, 45%);
    top: 12px;
    z-index: 1;
    pointer-events: none;
  }
}
</style>
