import Axios from "axios";
import { interceptor } from "./index";
import resolveURL from "@/utils/baseurl";
import resolveReservedFields from "@/utils/resolve-reserved-field";

const axios = Axios.create({ baseURL: resolveURL("/api/dataops") });

interceptor.auth(axios);

export async function getDefinitions(types = []) {
  const [sources, destinations] = await Promise.all(
    types.map(category => axios.get("/definitions", { params: { category } }))
  );
  return {
    data: [].concat(sources.data || [], destinations.data || []),
    response: sources.response
  };
}

export function saveDefinition(data) {
  return axios.post("/definition", data);
}

export function deleteDefinition(params) {
  return axios.delete("/definition", { params });
}

export function sortDefinitions(data, params) {
  return axios.post("/definition/sort", data, { params });
}

export function getSpecification(params) {
  return axios.get("/specification", { params });
}

export function getConnectors() {
  return axios.get("/list");
}

export function saveConnector(data) {
  return axios.post("", data);
}

export function saveTemplateConnector(data) {
  return axios.post("/connector/authorize", data);
}

export function deleteConnector(connectorId) {
  return axios.delete(connectorId);
}

export function getConnectionList() {
  return axios.get("/connection/list");
}

export function getConnectionDetail(id) {
  return axios.get(`/connection/${id}`);
}

export function getConnectionStatus() {
  return axios.get("/connection/status");
}

export function saveConnection(data) {
  return axios.post("/connection", data);
}

export function deleteConnection(connectionId) {
  return axios.delete(`/connection/${connectionId}`);
}

export function enableConnection(connectionId) {
  return axios.get(`/connection/enable/${connectionId}`);
}

export function disableConnection(connectionId) {
  return axios.get(`/connection/disable/${connectionId}`);
}

export function resetConnection(connectionId) {
  return axios.get(`/connection/reset/${connectionId}`);
}

export function sortConnection(data) {
  return axios.post("/connection/sortBatch", data);
}

export function getConnectorSchema(connectorId) {
  return axios.get(`/schema/${connectorId}`);
}

export function getJobs(connectionId) {
  return axios.get("/connection/task/list", { params: { id: connectionId } });
}

export function runJob(connectionId) {
  return axios.get(`/connection/run/${connectionId}`);
}

export function stopJob(connectionId) {
  return axios.get(`/connection/cancel/${connectionId}`);
}

export function getJobLog(jobId) {
  return axios.get(`/connection/task/log/${jobId}`);
}

// destination schema 相关，用于 sql 编辑器提示等地方
export function getDestinationTables(params) {
  return axios.get("/destination/tables", { params }).then(res => {
    const data = res.data || [];
    res.data = resolveReservedFields(data, item => item.tableName);
    return res;
  });
}

export function getDestinationColumns(params) {
  return axios.get("/destination/columns", { params }).then(res => {
    const data = res.data || [];
    res.data = resolveReservedFields(data, item => item.columnName);
    return res;
  });
}

export function getOAuthToken(app, params) {
  return axios.get(`../connector/${app}/accessToken`, { params });
}

export function getOAuthPageInfo(app) {
  return axios.get(`../connector/${app}/oauthPageInfo`);
}

export function sortConnector(data) {
  return axios.post("/connector/sortBatch", data);
}

// transform 相关
export function getTransformList() {
  return axios.get("/transform/list");
}

export function setTransformForConnector(data) {
  return axios.post("/connector/transform", data);
}

export function updateTransform(data) {
  return axios.post("/transform/update", data);
}

export function deleteTransform(id) {
  return axios.delete(`/transform/${id}`);
}

export function uploadTransform(file) {
  const formData = new FormData();
  formData.append("dbtProject", file);
  return axios.post("/transform/upload", formData);
}
