import * as monaco from "monaco-editor";
import * as sql from "monaco-editor/esm/vs/basic-languages/sql/sql";
import * as schema from "@/views/datamodel/utils/resolve-schema";
import * as utils from "./utils";

const CompletionItemKind = monaco.languages.CompletionItemKind;

/**
 * 定义为常量会偶尔出现找不到提示项问题，故而定义成函数。
 * @returns {object[]}
 */
function provideDefaultSuggestions() {
  return [].concat(
    sql.language.keywords.map(item => {
      return {
        label: item,
        kind: CompletionItemKind.Keyword,
        sortText: "4",
        detail: "Keyword",
        insertText: item
      };
    }),
    sql.language.operators.map(item => {
      return {
        label: item,
        kind: CompletionItemKind.Operator,
        sortText: "5",
        detail: "Operator",
        insertText: item
      };
    }),
    sql.language.builtinFunctions.map(item => {
      return {
        label: item,
        kind: CompletionItemKind.Function,
        sortText: "6",
        detail: "Function",
        insertText: item
      };
    })
  );
}

export const LANG_ID = "datasheet-sql";
// 自定义 lang 参见 monaco-editor/esm/vs/basic-languages/_.contribution.js
monaco.languages.register({ id: LANG_ID });
monaco.languages.setMonarchTokensProvider(LANG_ID, sql.language);
monaco.languages.setLanguageConfiguration(LANG_ID, sql.conf);
monaco.languages.registerCompletionItemProvider(LANG_ID, {
  triggerCharacters: [" ", "."],
  async provideCompletionItems(model, position) {
    const tables = await schema.resolveTables();
    const token = utils.tokenOfPointer(model, position);
    /**
     * 包含 `.` 字符的 token 仅提示字段列表
     */
    if (token.includes(".")) {
      const tableName = token.replace(/\..*/, "");
      const columns = await schema.resolveColumnsByTableName(tableName);
      const suggestions = columns.map(item => {
        const { fieldName, columnName, tableName } = item;
        const alias = columnName === fieldName ? "" : `(${columnName})`;
        return {
          label: fieldName,
          kind: CompletionItemKind.Field,
          sortText: "1",
          detail: `${item.fieldType} ${alias} <字段>`,
          documentation: `${tableName}.${fieldName}`,
          insertText: fieldName
        };
      });
      return { suggestions };
    }
    const suggestions = [].concat(
      tables.map(item => {
        return {
          label: item.tableAlias || item.tableName,
          kind: CompletionItemKind.Class,
          sortText: "2",
          detail: "<表>",
          insertText: item.tableName
        };
      }),
      await schema.allColumns().then(columns => {
        return columns.map(item => {
          const { tableName, columnName, fieldName } = item;
          const alias = columnName === fieldName ? "" : `(${columnName})`;
          return {
            label: `${tableName}.${fieldName}`,
            kind: CompletionItemKind.Field,
            sortText: "3",
            detail: `${item.fieldType} ${alias} <字段>`,
            insertText: `${tableName}.${fieldName}`
          };
        });
      }),
      provideDefaultSuggestions(),
      utils.tokens(model).map(item => {
        return {
          label: item,
          kind: CompletionItemKind.Text,
          sortText: "7",
          insertText: item
        };
      })
    );
    return { suggestions };
  }
});
