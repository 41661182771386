import * as ConnectionAPI from "@/api/connections";
import * as DatasheetAPI from "@/api/datasheet";
import * as Utils from "@/utils/function";
import workspace from "@/views/datamodel/models/workspace";
import FieldTypes from "./field-types";

/**
 * 根据 destinationId 获取目标库的表
 * @type {(destinationId: string) => Promise<any>}
 */
export const resolveTables = Utils.memoize(
  // memoize fn
  async destinationId => {
    destinationId = destinationId || workspace.currentDatasheet.destinationId;
    try {
      if (!destinationId) {
        return [];
      }
      const res = await ConnectionAPI.getDestinationTables({ destinationId });
      return res.data;
    } catch {
      return [];
    }
  },
  // memoize resolver
  destinationId => destinationId || workspace.currentDatasheet.destinationId
);

/**
 * 根据 tableName 获取 tableId
 * @param {string} tableName
 * @returns {Promise<string>}
 */
export async function getTableId(tableName) {
  const tables = await resolveTables();
  const table = tables.find(item => item.tableName === tableName);
  if (!table) {
    return null;
  }
  return table.id;
}

/**
 * 根据 tableId 获取 tableName
 * @param {string} tableId
 * @returns {Promise<string>}
 */
export async function getTableName(tableId) {
  const tables = await resolveTables();
  const table = tables.find(item => item.id === tableId);
  if (!table) {
    return null;
  }
  return table.tableName;
}

/**
 * 根据 tableId 获取字段列表
 * @type {(tableId: string) => Promise<object[]>}
 */
export const resolveColumns = Utils.memoize(async tableId => {
  try {
    if (!tableId) {
      return [];
    }
    const [columns, tableName] = await Promise.all([
      ConnectionAPI.getDestinationColumns({ tableId }).then(res => res.data),
      getTableName(tableId)
    ]);
    for (const item of columns) {
      item.tableName = tableName;
      item.fieldType = FieldTypes[item.fieldType] || "";
    }
    return columns;
  } catch {
    return [];
  }
});

/**
 * 根据 tableName 获取字段列表
 */
export async function resolveColumnsByTableName(tableName) {
  const tableId = await getTableId(tableName);
  return resolveColumns(tableId);
}

/**
 * 根据 datasheetId 获取工作表字段列表
 * @type {(datasheetId: string) => Promise<object[]>}
 */
export const resolveColumnsByDatasheetId = Utils.memoize(async datasheetId => {
  try {
    const res = await DatasheetAPI.getDatasheetColumns({ datasheetId });
    return res.data;
  } catch {
    return [];
  }
});

/**
 * 返回已加载的全部字段
 */
export async function allColumns() {
  /**
   * @type {Map<string, array>}
   */
  const cache = resolveColumns.cache;
  let columns = [];
  for await (const item of cache.values()) {
    columns = columns.concat(item);
  }
  return columns;
}

/**
 * 根据 datasheetId 获取同源视图列表。
 * 同源视图：数据直接或间接来源于同一个数据库，基于视图创建的新视图，以源视图为准。
 * @type {(datasheetId: string) => Promise<object[]>}
 */
export const resolveIsogenyDatasheets = Utils.memoize(async datasheetId => {
  try {
    const res = await DatasheetAPI.getDatasheetList({ datasheetId });
    return res.data;
  } catch {
    return [];
  }
});
